import { useContext } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import CampaignIcon from "@mui/icons-material/Campaign";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ScaleIcon from "@mui/icons-material/Scale";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PeopleIcon from "@mui/icons-material/People";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import PublicIcon from "@mui/icons-material/Public";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import LyricsIcon from "@mui/icons-material/Lyrics";
import MediaBluetoothOnIcon from "@mui/icons-material/MediaBluetoothOn";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import StyleIcon from "@mui/icons-material/Style";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CalculateIcon from "@mui/icons-material/Calculate";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HeadsetIcon from '@mui/icons-material/Headset';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {import FlagCircleIcon from '@mui/icons-material/FlagCircle';
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    "transform",
    "opacity",
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <MenuWrapper>
      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Dashboards
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/"
                startIcon={<BrightnessLowTwoToneIcon />}
              >
                Progress
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Songs
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div" sx={{
              display: "none"
            }}>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/song-report"
                startIcon={<LyricsIcon />}
              >
                Ballpark
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/sync/history"
                startIcon={<WorkHistoryIcon />}
              >
                Sync History
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                // to="song-popularity-weight"
                to="spotify-all-time-plays"

                startIcon={<WarehouseIcon />}
              >
                {/* Song Popularity Weight */}
                Spotify All Time Plays
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                // to="/legacy-artist-spotify-plays"
                to="/legacy-spotify-all-time-plays"
                startIcon={<MusicNoteIcon />}
              >
                {/* Legacy Artist Spotify Plays */}
                Legacy Spotify All Time Plays
              </Button>
            </ListItem>
          </List>
          
        </SubMenuWrapper>
      </List>
      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Campaigns
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/campaigns"
                startIcon={<CampaignIcon />}
              >
                Campaigns
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Notifications
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/notifications"
                startIcon={<NotificationsNoneIcon />}
              >
                Notifications
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            ARTISTS
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/artists"
                startIcon={<PeopleIcon />}
              >
                Artists
              </Button>
            </ListItem>
          </List>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/artist-willingness"
                startIcon={<MilitaryTechIcon />}
              >
                Artist Willingness
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                // to="/artist-spotify-weight"
                to="/artist-monthly-listeners"
                startIcon={<ArtTrackIcon />}
              >
                {/* Artist Spotify Weight */}
                Artist Monthly Listeners
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                // to="/monthly-listeners-spotify"
                to="/legacy-artist-monthly-listeners"
                startIcon={<HeadsetIcon />}
              >
                {/* Monthly Listeners Spotify */}
                Legacy Artist Monthly Listeners
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Brand
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/brand"
                startIcon={<ScaleIcon />}
              >
                Brand
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/brandweight"
                startIcon={<FitnessCenterIcon />}
              >
                Brand Weight
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/brandcategories"
                startIcon={<FitnessCenterIcon />}
              >
                Brand Categories
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Duration
            </ListSubheader>
          }
        ></List>

        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/duration"
                startIcon={<TimelapseIcon />}
              >
                Duration
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Media
            </ListSubheader>
          }
        ></List>
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/media"
                startIcon={<MediaBluetoothOnIcon />}
              >
                Media
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/sub_media"
                startIcon={<SubtitlesIcon />}
              >
                SubMedia
              </Button>
            </ListItem>

          </List>
        </SubMenuWrapper>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Geography
            </ListSubheader>
          }
        ></List>


        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/territory"
                startIcon={<FlagCircleIcon />}
              >
                Territory
              </Button>
            </ListItem>
          </List>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/region"
                startIcon={<PublicIcon />}
              >
                Region
              </Button>
            </ListItem>
          </List>
          {/* <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/geography"
                  startIcon={<LanguageIcon />}
                >
                  Geography
                </Button>
              </ListItem>
            </List> */}
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/country"
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Country
              </Button>
            </ListItem>
          </List>

        </SubMenuWrapper>
      </List>


      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Factors
          </ListSubheader>
        }
      ></List>


      <SubMenuWrapper>
        <List>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/instafollowers-factor"
              startIcon={<CalculateIcon />}
            >
              Insta Followers Factor
            </Button>
          </ListItem>
        </List>
        <List>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/monthely-listeners-factor"
              startIcon={<CalculateIcon />}
            >
              Monthely listeners Factor
            </Button>
          </ListItem>
        </List>
        <List>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/geography-factor"
              startIcon={<CalculateIcon />}
            >
              Geography Factor
            </Button>
          </ListItem>
        </List>
        <List>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/media-factor"
              startIcon={<CalculateIcon />}
            >
              Media Factor
            </Button>
          </ListItem>
        </List>
      </SubMenuWrapper>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            User
          </ListSubheader>
        }
      >

        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/users"
                startIcon={<PeopleOutlineIcon />}
              >
                Users
              </Button>
            </ListItem>

          </List>
        </SubMenuWrapper>
      </List>

      <List
        component="div"
        subheader={
          <ListSubheader component="div" disableSticky>
            Others
          </ListSubheader>
        }
      >
      </List>

      <SubMenuWrapper>
        <List component="div">
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/exclusivity"
              startIcon={<NotificationsPausedIcon />}
            >
              Exclusivity
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/cover_version"
              startIcon={<AddToDriveIcon />}
            >
              Cover Version
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/lyric_adjustment"
              startIcon={<LyricsIcon />}
            >
              Lyric Adjustment
            </Button>
          </ListItem>

          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/tags"
              startIcon={<StyleIcon />}
            >
              Tags
            </Button>
          </ListItem>

          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/base_price"
              startIcon={<EuroSymbolIcon />}
            >
              Base price
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/instagram-followers-weight"
              startIcon={<FollowTheSignsIcon />}
            >
              Insta Followers Weight
            </Button>
          </ListItem>

          <ListItem component="div">
            <Button
              disableRipple
              component={RouterLink}
              onClick={closeSidebar}
              to="/under-review-threshold"
              startIcon={<DataThresholdingIcon />}
            >
              Under Review Threshold
            </Button>
          </ListItem>

        
     


        </List>
      </SubMenuWrapper>
      <List
        component="div"
        sx={{
          display: "none",
        }}
        subheader={
          <ListSubheader component="div" disableSticky>
            Accounts
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/status/coming-soon"
                startIcon={<AccountCircleTwoToneIcon />}
              >
                User Profile
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/status/coming-soon"
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Account Settings
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
