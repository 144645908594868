import {
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useMutation, useQuery } from "react-query";
import { COLUMNS, changeUserStatus, getAllUsers, updateAccountTrial, changeAccountStatus, changeUserSongsTrail } from "src/services/users";
import Header from "src/components/Header/Header";
import TableData from "src/components/Table/TableData";
import SearchBar from "src/components/SearchBar/SearchBar";
import useDebounce from "src/hooks/useDebounce";
import Modal from "src/components/Modal/Modal";
import Loader from "src/components/Loader/Loader";
import { useDispatch } from "react-redux";
import { openSnackbar } from "src/redux/snackbar";

type Props = {};

const Users = (props: Props) => {
    const dispatch = useDispatch()
    const [usersData, setUsersData] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [searchValue, setSearchValue] = useState("");
    const [modifiedUsers, setModifiedUsers] = useState<boolean []>([])

    useEffect(() => {
        if (usersData?.results?.length && !modifiedUsers.length) {
            // Only initialize modifiedUsers if it's not already set
            setModifiedUsers(new Array(usersData.results.length).fill(false));
        }
    }, [usersData]);

    useEffect(() => {
        //on page change set the moified user to initial state.
        setModifiedUsers([])
    }, [page])


    // Function to update the modifiedUsers state
    const setUserModified = (index: number) => {
        setModifiedUsers((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = true; // Set to true when a change is made
            return updatedState;
        });
    };

    const changeTotalSong = (value,index) => {
        // Make a copy of usersData
        const updatedUsersData = { ...usersData };
        // Update the total_songs_limit for the specific user at the given index
        updatedUsersData.results[index].user_profile.total_songs_limit = value
        // Set the state with the updated usersData
        setUsersData(updatedUsersData);

        setUserModified(index); // Mark this user as modified
    }

    const changeExtraCredit = (value,index) => {
        const updatedUsersData = { ...usersData };
        // Update the total_songs_limit for the specific user at the given index
        updatedUsersData.results[index].user_profile.extra_credits = value
        // Set the state with the updated usersData
        setUsersData(updatedUsersData);

        setUserModified(index); // Mark this user as modified

    }

    const pageHandler = () => {
        if (page !== 1) {
            setPage(1);
        }
    };
    const debouncedSearchTerm = useDebounce(searchValue, 500, pageHandler)

    const {  refetch: refetchUsers,isFetching,data:allUsersData } = useQuery(
        ["users", debouncedSearchTerm],
        () => getAllUsers(page, limit, debouncedSearchTerm),
        {
            enabled: false,
            onSuccess: (data: any) => {
                setUsersData(data?.data);
              
            },
        }
    );

    const { isLoading: userStatusLoading, mutate: userStatusMutate } = useMutation(
        ["usersStatus"],
        (data: any) =>
            changeUserStatus(`/User/change_user_status/?user_id=${data?.id}&Status=${data?.status}`),
        {
            onSuccess: (data) => {
                refetchUsers();
            },
        }
    );

    const { isLoading: songsTrailLoading, mutate: SongsTrailMutate } = useMutation(
        ["songsTrail"],
        (data: any) => {
            console.log("Data before API call:", data);
            const extraCredits = data?.user_profile?.extra_credits === "" ? 0 : data?.user_profile?.extra_credits
            const totalSongsLimit = data?.user_profile?.total_songs_limit === "" ? 0 : data?.user_profile?.total_songs_limit
            return changeUserSongsTrail(`/User/change_user_songs_trial/?profile_user_id=${data?.user_profile?.id}&total_songs_limit=${totalSongsLimit}&extra_credits=${extraCredits}`);
            
        },
        {
            onSuccess:(data) => {
                dispatch(
                    openSnackbar({
                        message: data?.data?.message,
                        title: "Success",
                        type: "success",
                    })
                );
            }
        }
    );


    const { isLoading: accountStatusLoading, mutate: accountStatusMutate } = useMutation(
        ["accountStatus"],
        (data: any) =>
            changeAccountStatus(`/User/change_account_status/?user_profile_id=${data?.id}`),
        {
            onSuccess: (data) => {
                refetchUsers();
                dispatch(
                    openSnackbar({
                        message: data?.data?.detail,
                        title: "Success",
                        type: "success",
                    })
                );
            },
        }
    );



    useEffect(() => {
        refetchUsers()
    }, [page, limit, debouncedSearchTerm])

    const handleStatusChange = (data) => {
        userStatusMutate(data);
    }

    const handleChangeAccountStatus = (data) => {
        accountStatusMutate(data);
    }

    const saveSongsTrail  = (item:any,index) => {
        console.log("item",item)
        SongsTrailMutate(item,{
            onSuccess:() => {
                // Reset the modified state for the specific user only after a successful mutation
                setModifiedUsers((prevState) => {
                    const updatedState = [...prevState];
                    updatedState[index] = false;
                    return updatedState;
                });
                refetchUsers()
            },
            onError: (error) => {
                // Optionally, handle errors if necessary
                console.error("Failed to save song trail:", error);
            },
        })

    }

    

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <PageTitleWrapper isUsersPage={true}>
                <Header
                    label={"Users"}
                    name={"Users"}
                    disabled={true}
                    showBtn={false}
                />
            </PageTitleWrapper>
            <Container style={{maxWidth:"1600px"}}>
                <Grid   
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                                <SearchBar
                                    placeholder="Search by User name"
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            
                            <Card>
                            <CardHeader title="Users" />
                            <Divider />
                            <TableData
                                columns={COLUMNS}
                                data={usersData}
                                page={page}
                                setPage={setPage}
                                limit={limit}
                                setLimit={setLimit}
                                editLabel={"Edit User Account Trails"}
                                deleteLabel={"Delete User"}
                                disabled={true}
                                usersStatusBtn={handleStatusChange}
                                loading={userStatusLoading}
                                handleChangeAccountStatus={handleChangeAccountStatus}
                                loadingIsDemo={accountStatusLoading}
                                showActionBtn={true}
                                isFetching={isFetching}
                                addSaveBtn={true}
                                changeTotalSong={changeTotalSong}
                                changeExtraCredit={changeExtraCredit}
                                saveSongsTrail={saveSongsTrail}
                                modifiedUsers={modifiedUsers}
                                showEditButton={false}
                                showDeleteButton={false}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />

            {/* <Modal
                open={openUpdateModal}
                onClose={() => {
                    openUpdateModal && setOpenUpdateModal(false);
                }}
                title={`${openUpdateModal && "Update Account Type"}`}
            >
                <Box
                    component="form"
                    onSubmit={submitHandler}
                    noValidate
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "350px",
                        margin: "auto",
                        paddingX: "10px"
                    }}
                >
                    <TextField
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        label="Account Type"
                        name="account"
                        type="text"
                        onChange={InputChangeHandler}
                        select
                        sx={{
                            marginBottom: "20px",
                            textTransform:"capitalize"
                        }}
                        value={accountType}
                    >
                        <MenuItem value="trial account">Trial Account</MenuItem>
                        <MenuItem value="full access">Full Access</MenuItem>
                    </TextField>

                    {

                        accountType !== "full access" && (
                            <TextField
                                autoComplete="off"
                                margin="normal"
                                required
                                fullWidth
                                label="campaign limit"
                                name="campaign limit"
                                type="number"
                                onChange={handleChangeCampaignLimit}
                                error={isSubmitted && (campaignLimit === null || campaignLimit < 0)}
                                value={campaignLimit}
                            />
                        )
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            marginBottom: "20px",
                        }}
                        disabled={userAccountLoading}
                    >
                        {(() => {
                            if (userAccountLoading) {
                                return <Loader color={"inherit"} size={20} />;
                            } else if (openUpdateModal) {
                                return "Update";
                            } else {
                                return "Add";
                            }
                        })()}
                    </Button>
                </Box>
            </Modal> */}
        </>
    );
};

export default Users;
